import { 
  Grid,
  Box, 
  Button, 
  // Card, 
  Link,
   Typography, 
  // Container,
  //  Icon,
   styled, 
  //  Typography
  } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import logo from '../../layouts/AccentHeaderLayout/Header/Logo/Logo.svg';

// const Content = styled(Box)(
//   () => `
//     display: flex;
//     flex: 1;
//     width: 100%;
    
// `
// );

const StyledLink = styled(Link)`
    text-decoration: none;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`;

const colorTheme = '#FFDD00';
function Footer() {
  return (
    // <Content textAlign="left" style={{backgroundColor:colorTheme}}>
    //     <Container
    //       sx={{
    //         // display: 'flex',
    //         // alignItems: 'center',
    //         // flexDirection: 'column'
    //       }}
    //       // maxWidth="sm"
    //     
    <div style={{backgroundColor:colorTheme,paddingLeft:'5%',paddingRight:'5%'}}>
          <br/> <br/>
        <div style={{display:'flex',flexDirection:'row',width:'100%'}}> 
              <div style={{}}>
              <Button
                variant="contained"
                href="/"
                size="medium"
                style={{
                  // marginRight: '10px',
                  // marginLeft: '25px',
                  fontSize:'16px',
                  fontWeight:'bolder'
                }}
              >
                INICIO
              </Button>
              </div>
            <div style={{width:'100%',textAlign:'end',marginTop:'0%',height:'83px',overflow:'hidden'}}>
              <img src={logo} alt='' style={{height:'100%'}}/>
            </div>
        </div>
        {/* <br/> */}
        <hr style={{width:'100%',margin:'0 auto',color:'black',borderStyle:'solid'}}/>         
        {/* <br/> */}

        <Box sx={{ width: '100%' }}>
        <Grid container spacing={0} > 
                  
                  <Grid  item xs={3} 
                  // style={{
                  //   // boxShadow:'0 0 3px 0px black', 
                  //   width:'600px',marginRight:'0%',
                  //   borderRadius:'8px',borderStyle:'none',
                  //   borderWidth:'1px',borderColor:'black'
                  //   }}
                    >
                      <br/>
                    <Typography style={{fontSize:'16px',fontWeight:'bolder',color:'black'}}>
                    CONTACTO
                    </Typography>
                    <br/>
                    <Typography style={{color:'black',fontSize:'15px'}}>
                    perubclass@gmail.com
                    </Typography>
                    <Typography style={{color:'black',fontSize:'15px'}}>
                    923 793 549
                    </Typography>
                    <Typography style={{color:'black',fontSize:'15px'}}>
                    Av. Primavera 770 - Chacarilla del Estanque - Surco
                    </Typography>
                  </Grid>
                  
                  <Grid  item xs={4} 
                  // style={{
                  //   // boxShadow:'0 0 3px 0px black', 
                  //   width:'600px',
                  //   borderRadius:'8px',borderStyle:'none',
                  //   borderWidth:'1px',borderColor:'black',
                  //   paddingLeft:'5%',paddingRight:'5%'
                  //   }}
                    >

                      <br/>
                    <Typography style={{fontSize:'16px',fontWeight:'bolder',color:'black'}}>
                    HORARIOS
                    </Typography>
                    <br/>
                    
                    <Typography style={{color:'black',fontSize:'15px',textAlign:'left',width:'70%'}}>
                    Lunes - Viernes 06:00 AM a 09:00 PM / Sábado y Domingo 09:00 AM a 01:00PM
                    </Typography>
                    
                  </Grid>
                 
                  <Grid  item xs={3} align="left"
                  // style={{
                  //   // boxShadow:'0 0 3px 0px black', 
                  //   width:'600px',
                  //   borderRadius:'8px',borderStyle:'none',
                  //   borderWidth:'1px',borderColor:'black',
                  //   }}
                    >
                      <br/>
                    <Typography style={{fontSize:'16px',fontWeight:'bolder',color:'black'}}>
                    SOBRE NOSOTROS
                    </Typography>
                    <br/>
                    <Typography style={{color:'black',fontSize:'14px'}}>
                      <StyledLink href="https://wa.me/51923793549" target="_blank" style={{color:'black',fontWeight:'bolder'}}>
                            CHATEA CON NOSOTROS
                      </StyledLink>
                    </Typography>
                    <Typography style={{color:'black',fontSize:'14px',marginTop:'1%'}}>
                      <StyledLink href="/" style={{color:'black',fontWeight:'bolder'}}>
                            QUIENES SOMOS
                      </StyledLink>
                    </Typography>
                    <Typography style={{color:'black',fontSize:'14px',marginTop:'1%'}}>
                      <StyledLink href="https://media.bclassclient.com/TermsAndConditionsBClass_07_2024.pdf" target="_blank" style={{color:'black',fontWeight:'bolder'}}>
                            TÉRMINOS Y CONDICIONES
                      </StyledLink>
                    </Typography>
                    <Typography style={{color:'black',fontSize:'14px',marginTop:'1%'}}>
                      <StyledLink href="/" style={{color:'black',fontWeight:'bolder'}}>
                            PÓLITICAS DE PRIVACIDAD
                      </StyledLink>
                    </Typography>
                  </Grid>

              
                  <Grid  item xs={2} align="center"
                  
                  // style={{
                  //   // boxShadow:'0 0 3px 0px black', 
                  //   width:'600px',marginRight:'0%',
                  //   borderRadius:'8px',borderStyle:'none',
                  //   borderWidth:'1px',borderColor:'black',
                  //   position:'relative',justifyContent:'center'
                  //   }}
                    >
                      <br/>
                    <Typography style={{fontSize:'16px',fontWeight:'bolder',color:'black'}}>
                    SÍGUENOS EN
                    </Typography>
                    <br/>
                    {/* <div style={{position:'absolute',bottom:40,display:'flex',marginLeft:'25%'}}> */}
                    <Grid container spacing={1} style={{justifyContent:'center'}}>
                      <Grid item>
                        <Link href="https://www.facebook.com/bclassperu/" target="_blank">
                        <FacebookIcon sx={{color:'black',fontSize:40}}/>
                        </Link>
                      </Grid>  
                      
                      <Grid item>
                        <Link href="https://www.instagram.com/bclassperu/" target="_blank">
                        <InstagramIcon sx={{color:'black',fontSize:40}}/>
                        </Link>
                      </Grid>
                       
                      <Grid item> 
                        <Link href="https://wa.me/51923793549" target="_blank">
                        <WhatsAppIcon sx={{color:'black',fontSize:40}}/>
                        </Link>
                      </Grid>  
                    </Grid>
                    {/* </div> */}
                  </Grid>
                  
              </Grid>
            <br/>
            </Box>
    </div>
    
    // </Container>
    // </Content>
    // <FooterWrapper className="footer-wrapper" style={{backgroundColor:colorTheme}}>
    //   <Box
    //     p={4}
    //     display={{ xs: 'block', md: 'flex' }}
    //     alignItems="center"
    //     textAlign={{ xs: 'center', md: 'left' }}
    //     justifyContent="space-between"
    //   >
    //     <Box>
    //       <Typography variant="subtitle1">
    //         &copy; 2022 - MI TUTORA
    //       </Typography>
    //     </Box>
    //     <Typography
    //       sx={{
    //         pt: { xs: 2, md: 0 }
    //       }}
    //       variant="subtitle1"
    //     >
    //       Desarrollado por {' '}
    //       <Link style={{color:'black'}}
    //         href="https://www.pucp.edu.pe/"
    //         target="_blank"
    //         rel="noopener noreferrer"
    //       >
    //         DP2 - PUCP
    //       </Link>
    //     </Typography>
    //   </Box>
    // </FooterWrapper>
  );
}

export default Footer;
