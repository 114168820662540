/* eslint-disable */
import { useState, useRef, useEffect } from 'react';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Helmet } from 'react-helmet-async';
import Footer from 'src/components/Footer';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin, { DayGridView } from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import moment from 'moment';
import GroupIcon from '@mui/icons-material/Group';
import {
  Grid,
  Drawer,
  Box,
  Card,
  Divider,
  useMediaQuery,
  styled,
  useTheme,
  Typography
} from '@mui/material';

import { useDispatch, useSelector } from 'src/store';
import {
  getEvents,
  updateEvent,
  selectEvent,
  selectRange,
  openDrawerPanel,
  closeDrawerPanel
} from 'src/slices/calendar';
import PageHeader from './PageHeader';

import Actions from './Actions';
import EventDrawer from './EventDrawer';

const FullCalendarWrapper = styled(Box)(
  ({ theme }) => `
    // padding: ${theme.spacing(3)};
    & .fc-license-message {
      display: none;
    }

      .fc-button-primary{
        background-color:transparent !important;
        border-color:transparent !important;
        color:black !important;
        border: none !important;
      }

        .fc-button-primary:focus {
          outline: none !important;
          outline-offset: none !important;
          box-shadow: none !important;
      }

      .fc-header-toolbar{
        background-color:transparent;
        margin-top:5px !important;
        position:absolute !important;
        z-index:2;
        width:96% !important;
        // padding-right:2% !important;
        overflow-y: hidden; // hide vertical
        overflow-x: hidden; // hide horizonta
      }

      .fc th{
      border: none;
      border-bottom: 1px solid #ECECEC;
      background-color:#ECECEC;
      border-radius: 8px 8px 0px 0px; 
      overflow-y: hidden; // hide vertical
        overflow-x: hidden; // hide horizonta
      }

      /*Quitar bordes*/
      // .fc td, .fc th {
      //   border-style: none !important;
      // }
        .fc td {
        background-color: white !important;
          border-radius: 0px 0px 8px 8px; 
          border-style: none !important
        }

        .fc-scrollgrid {
        border: none !important;
      }

      // .fc-scrollgrid td:last-of-type {
      //   border-right: none !important;
      // }
        /*Fin de quitar bordes*/
    .fc {
      .fc-day-today {
        background-color: inherit !important;
        font-weight:bolder !important;
        // font-size: 16px;
      }
      .fc-col-header-cell {
        text-transform: uppercase !important;
        font-weight:lighter;
        padding: ${theme.spacing(1)};
        overflow-y: hidden; // hide vertical
        overflow-x: hidden; // hide horizonta
        // background: ${theme.colors.alpha.black[5]};
      }


    .fc-event-main{
     background-color: transparent;
    }

      // .fc-event{
      //   width: 98px !important;}
    .fc-view {
    background-color: transparent;
      .fc-event { 
        width: 94%;
        float: none;
        // margin: 0 auto;
        // padding-top:3%;
        margin-left:2%;
        // margin-right:2% !important;
        margin-top: 5%;
        border-radius:10px;
        // padding-left:;
    }
}
      
`
);

const selectedEventSelector = (state) => {
  const { events, selectedEventId } = state.calendar;

  if (selectedEventId) {
    return events.find((_event) => _event.id === selectedEventId);
  }
  return null;
};

function ApplicationsCalendar({altura}) {
  const theme = useTheme();

  const calendarRef = useRef(null);
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  // const { events, isDrawerOpen, selectedRange } = useSelector(
  //   (state) => state.calendar
  // );
  const [events,setEvents] = useState('');
  //const selectedEvent = useSelector(selectedEventSelector);
  const [date, setDate] = useState(new Date());
  const [view, setView] = useState('dayGridWeek');

  const handleDateToday = () => {
    const calItem = calendarRef.current;

    if (calItem) {
      const calApi = calItem.getApi();

      calApi.today();
      setDate(calApi.getDate());
    }
  };

  const changeView = (changedView) => {
    const calItem = calendarRef.current;

    if (calItem) {
      const calApi = calItem.getApi();
      console.log(changedView);
      calApi.changeView(changedView);
      setView(changedView);
    }
  };

  const handleDatePrev = () => {
    const calItem = calendarRef.current;

    if (calItem) {
      const calApi = calItem.getApi();

      calApi.prev();
      setDate(calApi.getDate());
    }
  };

  const handleDateNext = () => {
    const calItem = calendarRef.current;

    if (calItem) {
      const calApi = calItem.getApi();

      calApi.next();
      setDate(calApi.getDate());
    }
  };

  const handleAddClick = () => {
    dispatch(openDrawerPanel());
  };

  const handleRangeSelect = (arg) => {
    const calItem = calendarRef.current;

    if (calItem) {
      const calApi = calItem.getApi();

      calApi.unselect();
    }

    dispatch(selectRange(arg.start, arg.end));
  };

  // const handleEventSelect = (arg) => {
  //   dispatch(selectEvent(arg.event.id));
  // };

  const handleEventResize = async ({ event }) => {
    try {
      await dispatch(
        updateEvent(event.id, {
          allDay: event.allDay,
          start: event.start,
          end: event.end
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  // const handleEventDrop = async ({ event }) => {
  //   try {
  //     dispatch(
  //       updateEvent(event.id, {
  //         allDay: event.allDay,
  //         start: event.start,
  //         end: event.end
  //       })
  //     );
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  // const closeDrawer = () => {
  //   dispatch(closeDrawerPanel());
  // };

  // useEffect(() => {
  //   dispatch(getEvents());
  // }, [dispatch]);

  useEffect(() => {
    // const calItem = calendarRef.current;

    // if (calItem) {
    //   const calApi = calItem.getApi();
    //   const changedView = mobile ? 'listWeek' : 'dayGridMonth';

    //   calApi.changeView(changedView);
    //   setView(changedView);
    // }
    // setView('dayGridWeek');
    // setEvents({ 
    //   title: 'Second Event',
    //   start: '2024-07-10T12:30:00',
    //   end: '2024-07-10T13:30:00',
    // })
      setEvents([
        {
          "name": "RIDE CESAR R.",
          "disciplineName": "CYCLING CHACARILLA",
          "start": "2024-07-15T06:00:00-05:00",
          "duracion": 50,
          "capacity": 30,
          "reserves": 1,
          "instructorName": "CESAR R.",
          "status": 0,
          "color":"#F7F7F7",
          "textColor":"black"
        },
        {
          "name": "RIDE KIRVEN",
          "disciplineName": "CYCLING CHACARILLA",
          "start": "2024-07-15T07:00:00-05:00",
          "duracion": 50,
          "capacity": 30,
          "reserves": 1,
          "instructorName": "KIRVEN R",
          "status": 0,
          "color":"#F7F7F7",
          "textColor":"black"
        },
        {
          "name": "RIDE FRANCO",
          "disciplineName": "CYCLING CHACARILLA",
          "start": "2024-07-15T08:00:00-05:00",
          "duracion": 50,
          "capacity": 30,
          "reserves": 1,
          "instructorName": "FRANCO A.",
          "status": 0,
          "color":"#F7F7F7",
          "textColor":"black"
        },
        {
          "name": "RIDE CONDITIONING ARIELA",
          "disciplineName": "CYCLING MIRAFLORES",
          "start": "2024-07-15T08:00:00-05:00",
          "duracion": 50,
          "capacity": 30,
          "reserves": 1,
          "instructorName": "ARIELA O.",
          "status": 0,
          "color":"#F7F7F7",
          "textColor":"black"
        },
        {
          "name": "RIDE CONDITIONING ANDREA",
          "disciplineName": "CYCLING CHACARILLA",
          "start": "2024-07-16T06:00:00-05:00",
          "duracion": 50,
          "capacity": 30,
          "reserves": 1,
          "instructorName": "ANDREA C.",
          "status": 0,
          "color":"#F7F7F7",
          "textColor":"black"
        },
        {
          "name": "RIDE ANDREA",
          "disciplineName": "CYCLING CHACARILLA",
          "start": "2024-07-16T07:00:00-05:00",
          "duracion": 50,
          "capacity": 30,
          "reserves": 1,
          "instructorName": "ANDREA C.",
          "status": 0,
          "color":"#F7F7F7",
          "textColor":"black"
        },
        {
          "name": "RIDE CESAR R.",
          "disciplineName": "CYCLING MIRAFLORES",
          "start": "2024-07-16T08:00:00-05:00",
          "duracion": 50,
          "capacity": 30,
          "reserves": 1,
          "instructorName": "CESAR R.",
          "status": 0,
          "color":"#F7F7F7",
          "textColor":"black"
        },{
          "name": "RIDE CONDITIONING ANDREA",
          "disciplineName": "CYCLING CHACARILLA",
          "start": "2024-07-17T11:00:00-05:00",
          "duracion": 50,
          "capacity": 30,
          "reserves": 5,
          "instructorName": "ANDREA C.",
          "status": 1,
          "color":"#F7F7F7",
          "textColor":"black"
        },
        {
          "name": "RIDE ANDREA",
          "disciplineName": "CYCLING CHACARILLA",
          "start": "2024-07-17T12:00:00-05:00",
          "duracion": 50,
          "capacity": 30,
          "reserves": 7,
          "instructorName": "ANDREA C.",
          "status": 1,
          "color":"#F7F7F7",
          "textColor":"black"
        },
        {
          "name": "RIDE CESAR R.",
          "disciplineName": "CYCLING MIRAFLORES",
          "start": "2024-07-17T13:00:00-05:00",
          "duracion": 50,
          "capacity": 30,
          "reserves": 3,
          "instructorName": "CESAR R.",
          "status": 1,
          "color":"#F7F7F7",
          "textColor":"black"
        },
        {
          "name": "RIDE CESAR R.",
          "disciplineName": "CYCLING MIRAFLORES",
          "start": "2024-07-17T14:00:00-05:00",
          "duracion": 50,
          "capacity": 30,
          "reserves": 10,
          "instructorName": "CESAR R.",
          "status": 1,
          "color":"#F7F7F7",
          "textColor":"black"
        },
        {
          "name": "RIDE CONDITIONING ANDREA",
          "disciplineName": "CYCLING CHACARILLA",
          "start": "2024-07-18T06:00:00-05:00",
          "duracion": 50,
          "capacity": 30,
          "reserves": 1,
          "instructorName": "ANDREA C.",
          "status": 1,
          "color":"#F7F7F7",
          "textColor":"black"
        },
        {
          "name": "RIDE ANDREA",
          "disciplineName": "CYCLING CHACARILLA",
          "start": "2024-07-18T07:00:00-05:00",
          "duracion": 50,
          "capacity": 30,
          "reserves": 1,
          "instructorName": "ANDREA C.",
          "status": 1,
          "color":"#F7F7F7",
          "textColor":"black"
        },
        {
          "name": "RIDE CESAR R.",
          "disciplineName": "CYCLING MIRAFLORES",
          "start": "2024-07-18T08:00:00-05:00",
          "duracion": 50,
          "capacity": 30,
          "reserves": 1,
          "instructorName": "CESAR R.",
          "status": 1,
          "color":"#F7F7F7",
          "textColor":"black"
        }, 
        {
          "name": "RIDE CONDITIONING ANDREA",
          "disciplineName": "CYCLING CHACARILLA",
          "start": "2024-07-19T06:00:00-05:00",
          "duracion": 50,
          "capacity": 30,
          "reserves": 1,
          "instructorName": "ANDREA C.",
          "status": 1,
          "color":"#F7F7F7",
          "textColor":"black"
        },
        {
          "name": "RIDE ANDREA",
          "disciplineName": "CYCLING CHACARILLA",
          "start": "2024-07-19T07:00:00-05:00",
          "duracion": 50,
          "capacity": 30,
          "reserves": 1,
          "instructorName": "ANDREA C.",
          "status": 1,
          "color":"#F7F7F7",
          "textColor":"black"
        },
        {
          "name": "RIDE CESAR R.",
          "disciplineName": "CYCLING MIRAFLORES",
          "start": "2024-07-19T19:00:00-05:00",
          "duracion": 50,
          "capacity": 30,
          "reserves": 1,
          "instructorName": "CESAR R.",
          "status": 1,
          "color":"#F7F7F7",
          "textColor":"black"
        },
        {
          "name": "RIDE CONDITIONING ANDREA",
          "disciplineName": "CYCLING CHACARILLA",
          "start": "2024-07-20T06:00:00-05:00",
          "duracion": 50,
          "capacity": 30,
          "reserves": 1,
          "instructorName": "ANDREA C.",
          "status": 1,
          "color":"#F7F7F7",
          "textColor":"black"
        },
        {
          "name": "RIDE ANDREA",
          "disciplineName": "CYCLING CHACARILLA",
          "start": "2024-07-20T07:00:00-05:00",
          "duracion": 50,
          "capacity": 30,
          "reserves": 1,
          "instructorName": "ANDREA C.",
          "status":1,
          "color":"#F7F7F7",
          "textColor":"black"
        },
        {
          "name": "RIDE CESAR R.",
          "disciplineName": "CYCLING MIRAFLORES",
          "start": "2024-07-20T09:00:00-05:00",
          "duracion": 50,
          "capacity": 30,
          "reserves": 1,
          "instructorName": "CESAR R.",
          "status": 1,
          "color":"#F7F7F7",
          "textColor":"black"
        },
        {
          "name": "RIDE CONDITIONING ANDREA",
          "disciplineName": "CYCLING CHACARILLA",
          "start": "2024-07-21T06:00:00-05:00",
          "duracion": 50,
          "capacity": 30,
          "reserves": 1,
          "instructorName": "ANDREA C.",
          "status": 1,
          "color":"#F7F7F7",
          "textColor":"black"
        },
        {
          "name": "RIDE ANDREA",
          "disciplineName": "CYCLING CHACARILLA",
          "start": "2024-07-21T07:00:00-05:00",
          "duracion": 50,
          "capacity": 30,
          "reserves": 1,
          "instructorName": "ANDREA C.",
          "status":1,
          "color":"#F7F7F7",
          "textColor":"black"
        },
        {
          "name": "RIDE CESAR R.",
          "disciplineName": "CYCLING MIRAFLORES",
          "start": "2024-07-21T09:00:00-05:00",
          "duracion": 50,
          "capacity": 30,
          "reserves": 1,
          "instructorName": "CESAR R.",
          "status": 1,
          "color":"#F7F7F7",
          "textColor":"black"
        },
      ]);
  }, []);
  
  const renderEventContent = (eventInfo) => {
    //console.log(eventInfo.event._def.extendedProps)
    // console.log(eventInfo.event._instance.range.start)
    return (
      // ,boxShadow:'0 0 5px 0px black'
           <div style={{height:'170px',borderRadius:8,border:0,
            paddingTop:'5%',boxShadow:'0 0 2px 0px black'
           }}>
            {eventInfo.event._def.extendedProps.status == 1 ? (
              <>
                  <Typography noWrap style={{fontSize:'14px',fontWeight:'bold'}}>
                  &nbsp;{eventInfo.event._def.extendedProps.name}
                  </Typography>
                  <Typography noWrap style={{fontSize:'13px',fontWeight:'lighter'}}>
                  &nbsp;{eventInfo.event._def.extendedProps.disciplineName}
                  </Typography>
                  <Typography noWrap style={{fontSize:'13px',fontWeight:'lighter'}}>
                  &nbsp;{eventInfo.event._def.extendedProps.instructorName
                  }
                  </Typography>
                  <div style={{position:'absolute',bottom:7,width:'100%'}}>
                  <Typography noWrap style={{fontSize:'13px',fontWeight:'bold'}}>
                  &nbsp;{moment(eventInfo.event._instance.range.start).utc().format('HH:mm A')
                  }
                  </Typography>
                  <div style={{display:'flex',justifyContent:'space-between'}}>
                  <Typography noWrap style={{fontSize:'13px',fontWeight:'lighter'}}>
                  &nbsp;{eventInfo.event._def.extendedProps.duracion} min
                  </Typography >
                     <div style={{justifyContent:'flex-end',display:'flex'}}>
                         <GroupIcon style = {{transform: 'rotateY(180deg)'}}/>&nbsp;
                         <Typography noWrap style={{fontSize:'14px',fontWeight:'lighter'}}>
                         {eventInfo.event._def.extendedProps.reserves}/
                         {eventInfo.event._def.extendedProps.capacity} &nbsp;
                         </Typography>
                     </div>
                  </div>
                  </div>
            </>
            ):(
            <>
              <Typography noWrap style={{fontSize:'14px',fontWeight:'bold',color:'#9B9B9B'}}>
              &nbsp;{eventInfo.event._def.extendedProps.name}
              </Typography>
              <Typography noWrap style={{fontSize:'13px',fontWeight:'lighter',color:'#9B9B9B'}}>
              &nbsp;{eventInfo.event._def.extendedProps.disciplineName}
              </Typography>
              <Typography noWrap style={{fontSize:'13px',fontWeight:'lighter',color:'#9B9B9B'}}>
              &nbsp;{eventInfo.event._def.extendedProps.instructorName
              }
              </Typography>
              <div style={{position:'absolute',bottom:7,width:'100%'}}>
              <Typography noWrap style={{fontSize:'13px',fontWeight:'bold',color:'#9B9B9B'}}>
              &nbsp;{moment(eventInfo.event._instance.range.start).utc().format('HH:mm A')
              }
              </Typography>
              <div style={{display:'flex',justifyContent:'space-between'}}>
              <Typography noWrap style={{fontSize:'13px',fontWeight:'lighter',color:'#9B9B9B'}}>
              &nbsp;{eventInfo.event._def.extendedProps.duracion} min
              </Typography >
                 {/* <div style={{justifyContent:'flex-end',display:'flex'}}>
                     <GroupIcon style = {{transform: 'rotateY(180deg)'}}/>&nbsp;
                     <Typography style={{fontSize:'14px',fontWeight:'lighter'}}>
                     {eventInfo.event._def.extendedProps.reserves}/
                     {eventInfo.event._def.extendedProps.capacity} &nbsp;
                     </Typography>
                 </div> */}
              </div>
              </div>
        </>)}
           
           </div>
    )
};
  return (
    <>
     <FullCalendarWrapper style={{paddingLeft:'2%',paddingRight:'2%',backgroundColor:'transparent'}}>
              <FullCalendar
                // headerToolbar={ (Typography>XD</Typography>)}
                firstDay={1}
                allDayMaintainDuration
                initialDate={date}
                initialView={view}
                locale='es'
                allDaySlot= {false}
                slotMinTime= '00:00:00'
                slotMaxTime= '24:00:00'
                // droppable
                // editable
                // nowIndicator={false}
                eventDisplay="block"
                eventContent={renderEventContent}
                // eventClick={handleEventSelect}
                // eventDrop={handleEventDrop}
                dayMaxEventRows={30}
                eventResizableFromStart
                // eventResize={handleEventResize}
                events={events}
                headerToolbar={{start:'prev',center:'',end:'next'}}

                // headerToolbar={true}
                height={altura === "alta" ? 1000:700}
                // contentHeight={'auto'}
                ref={calendarRef}
                rerenderDelay={10}
                // select={handleRangeSelect}
                // selectable
                weekends
                displayEventTime='false'
                slotDuration={'24:00:00'}
                // slotMinWidth= {30}
                
                plugins={[
                  dayGridPlugin,
                  timeGridPlugin,
                  // interactionPlugin,
                  // listPlugin
                ]}
              />
     </FullCalendarWrapper>
          
    </>
  );
}

export default ApplicationsCalendar;
