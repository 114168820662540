import Footer from 'src/components/Footer';
import { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import {
  Link,
  Box,
  Card,
  Typography,
  // Container,
  // Alert,
  styled,
  ImageList,
  ImageListItem,
  // ImageListItemBar,
  Button
} from '@mui/material';
// import InfoIcon from '@mui/icons-material/Info';
import { Helmet } from 'react-helmet-async';
import Scrollbar from 'src/components/Scrollbar';
import Calendar from 'src/content/applications/Calendar';
// import { GoogleLogin } from 'react-google-login';
// import { gapi } from 'gapi-script';
// import { auth0Config } from 'src/config';
import HeaderButtons from 'src/layouts/AccentHeaderLayout/Header/Buttons';
// import useAuth from 'src/hooks/useAuth';
// import Auth0Login from '../LoginAuth0';
// import FirebaseAuthLogin from '../LoginFirebaseAuth';
// import JWTLogin from '../LoginJWT';
// import AmplifyLogin from '../LoginAmplify';

// const Content = styled(Box)(
//   () => `
//     display: flex;
//     flex: 1;
//     width: 100%;
// `
// );

const itemData = [
  {
    img: 'https://media.bclassclient.com/discipline_1.png',
    title: 'MEMBRESIAS OFICIALES',
    // author: '@bkristastucchio',
  },
  {
    img: 'https://media.bclassclient.com/discipline_2.png',
    title: 'MEMBRESIAS OFICIALES ¡POR APERTURA!',
    // author: '@rollelflex_graphy726',
  }
];


// const link = "/lista";

// const itemElegir = [
//   {
//     img: 'https://cf.bstatic.com/xdata/images/xphoto/300x240/140040328.jpg?k=2422f83516d0b754590b00ba86266be9e78cae94f3667c02f0357d2644e3282d&o=',
//     title: 'Lunahuaná',
//     author: 'A 128 km de Chaclacayo',
//   },
//   {
//     img: 'https://cf.bstatic.com/xdata/images/xphoto/300x240/140040342.jpg?k=74b4847767ec240d1266d69e14c90eac6a6cb12794f8e74f729132de538934b6&o=',
//     title: 'Huancayo',
//     author: 'A 170 km de Chaclacayo',
//   },
//   {
//     img: 'https://cf.bstatic.com/xdata/images/xphoto/300x240/140040316.jpg?k=da6276bef6e3844b893e0958c0a08e0ae39e2cc13b915507b75df705bba5d905&o=',
//     title: 'Oxapampa',
//     author: 'A 216 km de Chaclacayo',
//   },

// ];

function LoginCover() {
  // const navigate = useNavigate();
  // const { isAuthenticated } = useAuth();

  // const onGoogleLoginSuccess = (response) => {
  //   const data = {
  //     email: response.profileObj.email,
  //     firstName: response.profileObj.givenName,
  //     lastName: response.profileObj.familyName || '',
  //     imageUrl: response.profileObj.imageUrl
  //   };

  //   // TODO: Hay una manera en el template que maneja las redirecciones
  //   // de los log-sign in. Este es un workaround
  //   signin({ ...data });
  // };

//  const handleClick = ()=>{
//   console.log("XD");
//  }

  useEffect(() => {
    // if (isAuthenticated) {
    //   navigate('/sapt');
    // }
    // const initClient = () => {
    //   gapi.client.init({
    //     client_id: auth0Config.client_id,
    //     scope: ''
    //   });
    // };
    // gapi.load('client:auth2', initClient);
  }, []);
  
  const colorTheme = '#FFDD00';
  // const scrollStyle = {
  //   overflowY: 'scroll',
  //   border: '5px solid black',
  //   height: '500px',
  //   msOverflowStyle: 'none', // IE and Edge
  //   scrollbarWidth: 'none', // Firefox
  //   WebkitScrollbar: { display: 'none' }, // Hide scrollbar for Chrome, Safari, and Opera     
  //   };
  const HeaderWrapper = styled(Card)(
    ({ theme }) => `
      height: ${theme.header.height};
      background-color: transparent;
      justify-content: right;
      display: flex;
      align-items: center;
      border: none,
      boxshadow: none
  `
  );
  const HeaderWrapperHome = styled(Card)(
    ({ theme }) => `
      height: ${theme.header.height};
      background-color: transparent;
      justify-content: left;
      display: flex;
      align-items: center;
      border: none,
      boxshadow: none,
  `
  );

  // const HeaderWrapperHomeEnd = styled(Card)(
  //   () => `
  //     position:absolute
  //     background-color: transparent;
  //     justify-content: left;
  //     display: flex;
  //     align-items: center;
  //     border: none,
  //     boxshadow: none
  // `
  // );
  return (
    <>
    
      <Helmet>
        <title>BClass</title>
      </Helmet>
      <Scrollbar>
      <div style={{width:'100%',height:'81%'}}>
        <video loop autoPlay muted style={{
                height: "81%",
                width: "100%",
                objectFit:'fill',
                position:'absolute'
            }}
             disablePictureInPicture
            >
          <source src="https://media.bclassclient.com/home_mainVideoV2.mp4" type="video/mp4"/>
          <track src="" kind="captions" />
        </video>
          {/* <Button
        // startIcon={<ClassIcon size="1rem" />}
            variant="outlined"
            color="white"
            href="/"
            style={{
              marginRight: '15px',
              marginLeft: '15px'
            }}
         >
           INICIO
        </Button> */}
       {/* Nuevo Header  */}
        <div style={{display:'flex', justifyContent:'space-between'}}>
      <HeaderWrapperHome elevation={0}>
        <Button
            variant="contained"
            href="/"
            size="medium"
            style={{
              marginRight: '10px',
              marginLeft: '25px',
              fontSize:'14px'
            }}
          >
            INICIO
        </Button>
      </HeaderWrapperHome>
        <HeaderWrapper elevation={0}>
            <HeaderButtons/>
      </HeaderWrapper>
      </div>
      
      <Box sx={{ml: 15}} style={{position:'absolute',bottom:'22%'}}>

              <Typography style={{justifyContent:'center',
                color:'white',fontSize:'47px',fontWeight:'bold',width:'480px'}}>
              Sé la mejor versión de ti mismo!
              </Typography>
              <Typography style={{color:'white',fontSize:'20px'}} sx={{mt:1}} noWrap>
              El éxito es la suma de pequeños esfuerzos día tras día
              </Typography>
              <Button 
              sx={{mt:2,'&.MuiButton-root:hover':{bgcolor: 'transparent'}}}
              variant="outlined"
              color="white"
              href="/classes"
              style={{
                fontSize:'16px',
                border:0,
                backgroundColor:'#000000'
              }}
              >
                Reserva Aquí
              </Button>
      </Box>
      
      </div>
    {/* Inicio parte de abajo, QUIENES SOMOS */}
          <Card
            sx={{
              p: 1,
              height:'290px',
            }} 
            style={{backgroundColor:colorTheme,borderRadius:0}} 
          >
            <Box textAlign="center">
              <Typography
                sx={{
                  mt:2
                }}
                style={{color:'black',fontSize:'35px',fontWeight:'bold'}}
              >
                Quiénes Somos
              </Typography>
              <br/>
             <hr style={{width:'7%',margin:'0 auto',color:'black',borderStyle:'solid'}}/>
              <br/>
              <div style={{display:'flex',justifyContent:'center', alignContent:'center'}}>
              <Typography
                sx={{
                  mt:1
                }}
                style={{color:'black',fontSize:'19px',maxWidth:'700px',justifyContent:'center'}}
              >
                Somos una nueva empresa en Perú cuya misión es impulsar a descubrir los potenciales beneficios 
                que genera el Cycling InDoor e impactar directamente en todas las personas 
                para tener una vida saludable y plena.
              </Typography>
              </div>
            </Box>
          </Card>
      {/* NUESTROS PLANES */}
      <Card
            style={{backgroundColor:'#FFFFFF',borderRadius:0}} 
          >
            <Box textAlign="center">
              <Typography
                sx={{
                  mt:2
                }}
                style={{color:'#595959',fontSize:'28px',fontWeight:'bold'}}
              >
                NUESTROS PLANES
              </Typography>
              <br/>
             <hr style={{width:'10%',margin:'0 auto',color:'black',borderStyle:'solid'}}/>
              <br/>
              <div style={{display:'flex',justifyContent:'center', alignContent:'center'}}>
              <Typography
                sx={{
                  mt:1
                }}
                style={{color:'#595959',fontSize:'19px',maxWidth:'700px',justifyContent:'center'}}
              >
                Costos de membresía oficiales
              </Typography>
              </div>
              <br/>
              <div style={{display:'flex',alignContent:'center',
                justifyContent:'center',alignItems:'center'
              }}>
              <ImageList cols={2} gap={20} style={{width:'1100px'}} >
                {itemData.map((item) => (
                    <ImageListItem key={item.img}>
                      <img
                       style={{border:'none',width:'100%',
                        height:'100%'
                       }}
                        // srcSet={`${item.img}`}
                        src={`${item.img}`}
                        alt={item.title}
                        loading="lazy"
                      />
                      <Typography sx={{mt:1}} style={{fontSize:'17px'
                        ,fontWeight:'bold',color:'#595959'
                      }}>{item.title}</Typography>
                    </ImageListItem>
                  ))}
              </ImageList>
              </div>
              <div style={{display:'flex',alignContent:'center',
                justifyContent:'center',alignItems:'center'
              }}>
                  <Button 
                    sx={{mt:2,'&.MuiButton-root:hover':{bgcolor: 'transparent'}}}
                    variant="outlined"
                    color="white"
                    href="/"
                    style={{
                      fontSize:'16px',
                      border:0,
                      backgroundColor:'#000000'
                    }}
                  >
                ¡ADQUIERELAS AQUÍ!
              </Button>
              </div>
              <div><br/><br/></div>
            </Box>
          </Card>
      {/* Clases Presenciales */}
      <Card
        style={{borderRadius:0,
          backgroundImage:'url(https://media.bclassclient.com/calendar_background_old.jpg)',
          backgroundPosition:'center center',backgroundRepeat:'no-repeat',
          backgroundSize:'cover',width:'100%',
          // height:'968px',
          height:'1050px',
        }} 
          >
            <Box textAlign="center">
              <Typography
                sx={{
                  mt:3
                }}
                style={{color:'white',fontSize:'28px',fontWeight:'bold'}}
              >
                Clases Presenciales
              </Typography>
              <br/>
             <hr style={{width:'12%',margin:'0 auto',color:'black',borderStyle:'solid'}}/>
              <br/>
              <div style={{display:'flex',justifyContent:'center', alignContent:'center'}}>
              <Typography
                sx={{
                  mt:1
                }}
                style={{color:'white',fontSize:'18px',maxWidth:'700px',justifyContent:'center'}}
              >
                Se mejor cada día ¡Sin excusas!
              </Typography>
              </div>
              <br/>

              {/* Aqui va el calendario    */}
              <Calendar/>

              <div style={{display:'flex',alignContent:'center',
                justifyContent:'center',alignItems:'center'
              }}>
                  <Button 
                    sx={{mt:2,'&.MuiButton-root:hover':{bgcolor: 'transparent'}}}
                    variant="outlined"
                    color="white"
                    href="/classes"
                    style={{
                      fontSize:'16px',
                      border:0,
                      backgroundColor:'#000000'
                    }}
                  >
                IR A CLASES!
              </Button>
              </div>
              <div><br/><br/></div>
            </Box>
          </Card>
        {/* Nuestros Planes 2 */}
        <Card
            style={{backgroundColor:'#FFFFFF',borderRadius:0}} 
          >
            <br/>
            <Box textAlign="center">
              <Typography
                sx={{
                  mt:2
                }}
                style={{color:'#595959',fontSize:'35px',fontWeight:'bold'}}
              >
                Nuestros Planes
              </Typography>
              <br/>
             <hr style={{width:'10%',margin:'0 auto',color:'black',borderStyle:'solid'}}/>
              <br/>
              <div style={{display:'flex',justifyContent:'center', alignContent:'center'}}>
              <Typography
                sx={{
                  mt:1
                }}
                style={{color:'#595959',fontSize:'19px',maxWidth:'700px',justifyContent:'center'}}
              >
               La motivación es lo que te pone en marcha, y el hábito es lo que hace que sigas.
              </Typography>
              </div>
              <br/><br/>
              <div style={{display:'flex',alignContent:'center',
                justifyContent:'center',alignItems:'center',
              }}>
                  <div style={{boxShadow:'0 0 3px 0px black', 
                    width:'350px',height:'430px',marginRight:'3%',
                    borderRadius:'8px',borderStyle:'solid',
                    borderWidth:'1px',borderColor:'black',
                    overflow:'auto'
                    }}>
                      <br/>
                    <Typography style={{fontSize:'18px',fontWeight:'bolder',color:'black'}}>
                    PROMOCION FIESTAS PATRIAS - 40 CLASES
                    </Typography>
                    <br/>
                    <Typography>
                     Pago único
                    </Typography>
                    <div style={{display:'flex',justifyContent:'center'}}>
                      <Typography style={{fontSize:'18px',fontWeight:'bolder',color:'black'}}>
                      S/.
                      </Typography>
                      <Typography style={{fontSize:'18px',fontWeight:'bolder',color:'black'}}>
                      999
                      </Typography>
                      <Typography style={{fontSize:'18px',fontWeight:'lighter',color:'black'}}>
                      &nbsp;PEN
                      </Typography>
                    </div> 
                    {/* <br/> */}
                    <div style={{display:'flex',justifyContent:'center'}}>
                      <Typography>
                        Duración:&nbsp;
                      </Typography>
                      <Typography>
                          12
                      </Typography>
                      <Typography>
                      &nbsp;meses
                      </Typography>
                    </div>
                    <div style={{marginTop:'18%'}}>
                    <Typography style={{fontSize:'13px',fontWeight:'bold',color:'black'}}>
                        Clases:
                      </Typography>
                      <div style={{display:'flex',justifyContent:'center'}}>
                        <Typography style={{fontSize:'13px'}}>
                            40
                        </Typography>
                        <Typography style={{fontSize:'13px'}}>
                        &nbsp;Presencial
                        </Typography>
                      </div>
                    </div>
                    <div style={{marginTop:'18%'}}>
                    <Typography sx={{textDecoration: 'underline',fontWeight:'bold',color:'black',fontSize:'13px'}}>
                      <Link href="/" style={{color:'black'}}>
                          Ver detalle
                        </Link>
                      </Typography>
                      <Button 
                            sx={{mt:1,'&.MuiButton-root:hover':{bgcolor: 'transparent'}}}
                            variant="outlined"
                            // color="#000000"
                            href="/"
                            style={{
                              color:'black',
                              fontSize:'16px',
                              // boxShadow:'0 0 1px 0px black',
                              borderWidth:'2px',
                              borderColor:'black',
                              backgroundColor:'white',
                            }}
                          >
                        ADQUIRIR MEMBRESÍA
                      </Button>
                    </div>
                  </div>
                  <div style={{boxShadow:'0 0 3px 0px black', 
                    width:'350px',height:'430px',marginRight:'3%',
                    borderRadius:'8px',borderStyle:'solid',
                    borderWidth:'1px',borderColor:'black',
                    overflow:'auto'
                    }}>
                      <br/>
                    <Typography style={{fontSize:'18px',fontWeight:'bolder',color:'black'}}>
                    PROMOCION FIESTAS PATRIAS - 20 CLASES
                    </Typography>
                    <br/>
                    <Typography>
                     Pago único
                    </Typography>
                    <div style={{display:'flex',justifyContent:'center'}}>
                      <Typography style={{fontSize:'18px',fontWeight:'bolder',color:'black'}}>
                      S/.
                      </Typography>
                      <Typography style={{fontSize:'18px',fontWeight:'bolder',color:'black'}}>
                      499
                      </Typography>
                      <Typography style={{fontSize:'18px',fontWeight:'lighter',color:'black'}}>
                      &nbsp;PEN
                      </Typography>
                    </div> 
                    {/* <br/> */}
                    <div style={{display:'flex',justifyContent:'center'}}>
                      <Typography>
                        Duración:&nbsp;
                      </Typography>
                      <Typography>
                          6
                      </Typography>
                      <Typography>
                      &nbsp;meses
                      </Typography>
                    </div>
                    <div style={{marginTop:'18%'}}>
                    <Typography style={{fontSize:'13px',fontWeight:'bold',color:'black'}}>
                        Clases:
                      </Typography>
                      <div style={{display:'flex',justifyContent:'center'}}>
                        <Typography style={{fontSize:'13px'}}>
                            20
                        </Typography>
                        <Typography style={{fontSize:'13px'}}>
                        &nbsp;Presencial
                        </Typography>
                      </div>
                    </div>
                    <div style={{marginTop:'18%'}}>
                    <Typography sx={{textDecoration: 'underline',fontWeight:'bold',color:'black',fontSize:'13px'}}>
                      <Link href="/" style={{color:'black'}}>
                          Ver detalle
                        </Link>
                      </Typography>
                      <Button 
                            sx={{mt:1,'&.MuiButton-root:hover':{bgcolor: 'transparent'}}}
                            variant="outlined"
                            // color="#000000"
                            href="/"
                            style={{
                              color:'black',
                              fontSize:'16px',
                              // boxShadow:'0 0 1px 0px black',
                              borderWidth:'2px',
                              borderColor:'black',
                              backgroundColor:'white',
                            }}
                          >
                        ADQUIRIR MEMBRESÍA
                      </Button>
                    </div>
                  </div>
                  <div style={{boxShadow:'0 0 3px 0px black', 
                    width:'350px',height:'430px',marginRight:'',
                    borderRadius:'8px',borderStyle:'solid',
                    borderWidth:'1px',borderColor:'black',
                    overflow:'auto'
                    }}>
                      <br/>
                    <Typography style={{fontSize:'18px',fontWeight:'bolder',color:'black'}}>
                    07 CLASES - POR APERTURA
                    </Typography>
                    <br/>
                    <Typography>
                     Pago único
                    </Typography>
                    <div style={{display:'flex',justifyContent:'center'}}>
                      <Typography style={{fontSize:'18px',fontWeight:'bolder',color:'black'}}>
                      S/.
                      </Typography>
                      <Typography style={{fontSize:'18px',fontWeight:'bolder',color:'black'}}>
                      300
                      </Typography>
                      <Typography style={{fontSize:'18px',fontWeight:'lighter',color:'black'}}>
                      &nbsp;PEN
                      </Typography>
                    </div> 
                    {/* <br/> */}
                    <div style={{display:'flex',justifyContent:'center'}}>
                      <Typography>
                        Duración:&nbsp;
                      </Typography>
                      <Typography>
                          4
                      </Typography>
                      <Typography>
                      &nbsp;meses
                      </Typography>
                    </div>
                    <div style={{marginTop:'18%'}}>
                    <Typography style={{fontSize:'13px',fontWeight:'bold',color:'black'}}>
                        Clases:
                      </Typography>
                      <div style={{display:'flex',justifyContent:'center'}}>
                        <Typography style={{fontSize:'13px'}}>
                            7
                        </Typography>
                        <Typography style={{fontSize:'13px'}}>
                        &nbsp;Presencial
                        </Typography>
                      </div>
                      <div style={{display:'flex',justifyContent:'center'}}>
                        <Typography style={{fontSize:'13px',fontWeight:'bold',color:'black'}}>
                            Congelamiento:&nbsp;
                        </Typography>
                        <Typography style={{fontSize:'13px'}}>
                            30
                        </Typography>
                        <Typography style={{fontSize:'13px'}}>
                        &nbsp;día(s)
                        </Typography>
                      </div>
                    </div>
                    <div style={{marginTop:'18%'}}>
                    <Typography sx={{textDecoration: 'underline',fontWeight:'bold',color:'black',fontSize:'13px'}}>
                      <Link href="/" style={{color:'black'}}>
                          Ver detalle
                        </Link>
                      </Typography>
                      <Button 
                            sx={{mt:1,'&.MuiButton-root:hover':{bgcolor: 'transparent'}}}
                            variant="outlined"
                            // color="#000000"
                            href="/"
                            style={{
                              color:'black',
                              fontSize:'16px',
                              // boxShadow:'0 0 1px 0px black',
                              borderWidth:'2px',
                              borderColor:'black',
                              backgroundColor:'white',
                            }}
                          >
                        ADQUIRIR MEMBRESÍA
                      </Button>
                    </div>
                  </div>
              </div>
              <br/>
              <div style={{display:'flex',alignContent:'center',
                justifyContent:'center',alignItems:'center'
              }}>
                  <Button 
                    sx={{mt:2,'&.MuiButton-root:hover':{bgcolor: 'transparent'}}}
                    variant="outlined"
                    color="white"
                    href="/plans"
                    style={{
                      fontSize:'16px',
                      border:0,
                      backgroundColor:'#000000'
                    }}
                  >
                VER MÁS PLANES
              </Button>
              </div>
              <div><br/><br/><br/><br/></div>
            </Box>
          </Card>
        <Footer/>
      </Scrollbar>
    </>
  );
}

export default LoginCover;
