import { Box, Button, 
  // IconButton 
} from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import { useNavigate } from 'react-router-dom';

// import HeaderNotifications from './Notifications';
// import LanguageSwitcher from './LanguageSwitcher';

// import ClassIcon from '@mui/icons-material/Class';
// import ChatIcon from '@mui/icons-material/Chat';

function HeaderButtons() {
  // const { user,logout,isAuthenticated } = useAuth();
  const { logout,isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
      // window.location.replace('https://inf227i4.inf.pucp.edu.pe/');
    } catch (err) {
      console.error(err);
    }
  };

  // useEffect(()=>{
  //   console.log(user);
  // },[]);
  return (
    <Box
      sx={{
        mr: 1
      }}
    >
      <div style={{
          display: 'flex',
          alignItems: 'center'
      }}>
      {/* { isAuthenticated === true ? (
      <Typography style={{color:'white',fontSize:'18px'}}>
       ¡Bienvenido, {user.nombre}!
      </Typography>):(<></>)
      } */}
      <Button
            // startIcon={<ClassIcon size="1rem" />}
            size='small'
            variant="outlined"
            color="white"
            href="/classes"
            style={{
              fontSize:'12px',
              fontWeight:'bold',
               border:0
            }}
            sx={{ '&.MuiButton-root:hover':{bgcolor: 'transparent'} }}
          >
            CLASES
      </Button>
      <Button
            // startIcon={<ClassIcon size="1rem" />}
            variant="outlined"
            color="white"
            href="/about_us"
            style={{
              fontSize:'12px',
              fontWeight:'bold',
               border:0
            }}
            sx={{ '&.MuiButton-root:hover':{bgcolor: 'transparent'} }}
          >
            QUIENES SOMOS
      </Button>
      <Button
            // startIcon={<ClassIcon size="1rem" />}
            size='small'
            variant="outlined"
            color="white"
            href="/plans"
            style={{
              marginRight: '14px',
              fontSize:'12px',
               fontWeight:'bold',
               border:0
            }}
            sx={{ '&.MuiButton-root:hover':{bgcolor: 'transparent'} }}
          >
            PLANES
      </Button>

      {/* { isAuthenticated === true ? (
      <Button
        // startIcon={<ClassIcon size="1rem" />}
        variant="outlined"
        color="white"
        href=""
        style={{
          marginRight: '15px'
        }}
      >
       Lista de alojamientos
      </Button>):(
      <Button
        // startIcon={<ClassIcon size="1rem" />}
        variant="outlined"
        color="white"
        href="/classes"
        style={{
          marginRight: '15px'
        }}
      >
       Lista de alojamientos
      </Button>)
      } */}

      { isAuthenticated === true ? (
      <Button
        // startIcon={<ClassIcon size="1rem" />}
        variant="outlined"
        color="white"
        href="/hotel/busqueda"
        style={{
          marginRight: '15px'
        }}
      >
       Busqueda
      </Button>):(<></>)
      }
      {/* <Button
        // startIcon={<ClassIcon size="1rem" />}
        variant="outlined"
        color="white"
        href="/aim/student/reservations"
        style={{
          marginRight: '15px'
        }}
      >
       Hazte una cuenta
      </Button> */}
      { isAuthenticated === false ? (
          <Button
          // startIcon={<ClassIcon size="1rem" />}
          size='medium'
          variant="outlined"
          color="white"
          href="/"
          style={{
            maxHeight:'40px',
            width:'115px',
            marginRight: '28px',
            fontSize:'13px',
            color:'#000000',
            borderColor:'#000000',
            border:'2px solid'
          }}
          sx={{ '&.MuiButton-root:hover':{bgcolor: 'transparent'} }}
        >
          INGRESAR
        </Button>
      ):<></>
      }
       { isAuthenticated === true ? (
      <Button
        // startIcon={<ClassIcon size="1rem" />}
        variant="outlined"
        color="white"
        // href="/sign-in"
        style={{
          marginRight: '15px'
        }}
        onClick={handleLogout}
      >
       Cerrar sesión
      </Button>):<></>
      }
      <Button
        // startIcon={<ClassIcon size="1rem" />}
        size='medium'
        variant="outlined"
        color="white"
        href="/"
        style={{
          width:'115px',
          marginRight: '23px',
          fontSize:'13px',
          border:0,
          backgroundColor:'#000000'
        }}
        sx={{ '&.MuiButton-root:hover':{bgcolor: 'transparent'} }}
      >
        COMPRAR
      </Button>
      </div>
    </Box>
  );
}

export default HeaderButtons;