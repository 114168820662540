import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { Box, useTheme } from '@mui/material';

const Scrollbar = ({ className, children, autoHide = false, ...rest }) => {
  const theme = useTheme();

  return (
    <>
    <Scrollbars
      autoHide = {autoHide}
      renderThumbVertical={() => {
        return (
          <Box 
            sx={{
              background: '#888888',
              borderRadius: '6px',
              width:'6px',
              transition: `${theme.transitions.create(['background'])}`,
              '&:hover': {
                background: '#555555'
              }
            }}
          />
        );
      }}
      renderTrackVertical={() => <div style={{position:'absolute',width: '6px' 
        ,right: '0px',bottom: '0px',top: '0px',borderRadius: '6px',background:'#FFFFFF'}}/>}
      {...rest}
    >
      {children}
    </Scrollbars>
    </>
  );
};

Scrollbar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Scrollbar;
